import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts'
// import style from '../components/post/index.module.css'

import HeroSlider from '../components/hero/truckslider.js'

class BlogPage extends React.Component {
  render() {
    console.log('this.props', this.props)
    const posts = this.props.data.allSanityPost.edges.map((post) => post.node)
    console.log('posts', posts)
    return (
      <Layout head={{ title: 'Lunch Truck Advertising' }}>
        <HeroSlider />

        <div className="container py-5">
          <div className="row justify-content-center">
            {posts.map((post) => (
              <div className="col-md-4" key={post.id}>
                <div className="post">
                  <h2>
                    <Link to={`/${post.slug.current}`}>{post.title}</Link>
                  </h2>
                  <br />
                  <img
                    src={post.mainImage.asset.fluid.src}
                    alt={post.title}
                    className="img-fluid"
                  />
                  <small>
                    {post.publishedAt ? post.publishedAt : post._createdAt}
                  </small>
                  <p>{post.excerpt ? post.excerpt : null}</p>
                  <div class="d-flex justify-content-center mt-4">
                    <Link class="link-btn-green" to={`/${post.slug.current}`}>
                      View Post
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPage

export const BlogQuery = graphql`
  query BlogQuery {
    allSanityPost {
      edges {
        node {
          id
          title
          slug {
            current
          }
          _createdAt(formatString: "MMMM DD, YYYY")
          publishedAt(formatString: "MMMM DD, YYYY")
          excerpt
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }
`

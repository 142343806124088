import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'
import truck01 from '../../images/truckslider-01-min.jpg'
// import truck02 from '../../images/truckslider-02-min.jpg'
import truck03 from '../../images/truckslider-03-min.jpg'
import truck04 from '../../images/truckslider-04-min.jpg'
/*import truck05 from '../../images/truckslider-05-min.jpg'*/
// import truck06 from '../../images/truckslider-06-min.jpg'
import truck07 from '../../images/truckslider-07-min.jpg'
// import truck08 from '../../images/truckslider-08-min.jpg'
// import truck09 from '../../images/truckslider-09-min.jpg'
import truck10 from '../../images/truckslider-10-min.jpg'

export default class TruckSlider extends Component {
	render() {
		const params = {
			loop: true,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
			},
			clickable: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		}

		return (
			<Swiper {...params}>
				<div>
					<img src={truck01} className="img-fluid" alt="Truck Slider 01" />
				</div>

				<div>
					<img src={truck03} className="img-fluid" alt="Truck Slider 03" />
				</div>
				<div>
					<img src={truck04} className="img-fluid" alt="Truck Slider 04" />
				</div>

				<div>
					<img src={truck07} className="img-fluid" alt="Truck Slider 07" />
				</div>

				{/*	
					<div>
						<img src={truck02} className="img-fluid" alt="Truck Slider 02" />
					</div>
					<div>
						<img src={truck05} className="img-fluid" alt="Truck Slider 05" />
					</div>
					<div>
					<img src={truck06} className="img-fluid" alt="Truck Slider 06" />
					</div>
					<div>
					<img src={truck08} className="img-fluid" alt="Truck Slider 08" />
					</div>
					<div>
          <img src={truck09} className="img-fluid" alt="Truck Slider 09" />
        </div>*/}
				<div>
					<img src={truck10} className="img-fluid" alt="Truck Slider 10" />
				</div>
			</Swiper>
		)
	}
}
